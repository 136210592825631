<template>
  <div>
    <title>SIM INVENTORY ~ FINISH GOOD OUT</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        Finish Good Out Data Lists
        <br />
        <h4>The following is a list of finish good out lists</h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Dashboard</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <!-- Small boxes (Stat box) -->
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <div class="col-md-12">
          <router-link to="/add-finishgood-out">
            <button type="button" class="btn btn-info">
              <i class="fa fa-plus"> Add Data </i>
            </button>
          </router-link>
          <br /><br />
          <div class="box box-primary">
            <div class="box-header">
              <div class="box-header with-border">
                <i class="fa fa-list"></i>
                <h3 class="box-title">Finish Good Out Data</h3>
              </div>
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-2">
                    Show &nbsp; :
                    <select
                      v-model="pageSize"
                      @change="handlePageSizeChange($event)"
                    >
                      <option
                        v-for="size in pageSizes"
                        :key="size"
                        :value="size"
                      >
                        {{ size }}
                      </option>
                    </select>
                    results
                  </div>
                  <div class="col-xs-5">
                    <input
                      type="text"
                      autocomplete="off"
                      placeholder="Search by Batch Code"
                      class="form-control"
                      v-model="namesearch"
                    />
                  </div>
                  <div class="col-xs-2">
                    <button
                      @click="searchdata()"
                      type="button"
                      class="btn btn-info"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- /.box-header -->
            <div v-if="isExist" class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Out Number</th>
                      <th>Out Date</th>
                      <th>Buyer Code</th>
                      <th>Customs Number</th>
                      <th>Customs Date</th>
                      <th>BC Type</th>
                      <th>Invoice Number</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(classdata, index) in list_paged"
                      :key="classdata.ID"
                    >
                      <td>{{ index + 1 + (page - 1) * pageSize }}</td>

                      <!-- <td>{{ classdata.id }}</td> -->
                      <td>{{ classdata.nomor_pengeluaran }}</td>
                      <td>{{ classdata.tanggal_pengeluaran }}</td>
                      <td>{{ classdata.kode_buyer }}</td>
                      <td>{{ classdata.nomor_kepabeanan }}</td>
                      <td>{{ classdata.tanggal_kepabeanan }}</td>
                      <td>{{ classdata.jenis_kepabeanan }}</td>
                      <td>{{ classdata.no_invoice }}</td>
                      <td>
                        <router-link
                          :to="
                            '/detail-finishgood-out/' +
                              classdata.nomor_pengeluaran
                          "
                        >
                          <button
                            title="Detail Finish Good Out"
                            class="btn btn-success"
                          >
                            <i class="fa fa-list"></i>
                          </button>
                        </router-link>
                        &nbsp;
                        <!--<router-link :to="'/edit-finishgood-out/' + classdata.ID">
                          <button title="Edit Finish Good Out" class="btn btn-info">
                            <i class="fa fa-edit"></i>
                          </button>
                        </router-link>
                        <br><p></p> -->
                        <button
                          title="Delete Finish Good Out"
                          @click="deleteData(classdata.ID)"
                          class="btn btn-danger"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <v-pagination
                  v-model="page"
                  :pages="count"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="handlePageChange"
                />
              </div>
            </div>
            <div v-else class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Out Number</th>
                      <th>Out Date</th>
                      <th>Buyer Code</th>
                      <th>Customs Number</th>
                      <th>Customs Date</th>
                      <th>BC Type</th>
                      <th>Invoice Number</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="9" class="text-center">
                          <b style="color:red"> ... Data Not Found ... </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import swal from "sweetalert";
import axios from "axios";

export default {
  name: "fgoutread",
  components: {
    VPagination
  },
  data() {
    return {
      tipebc: [
        { value: "25", id: 1 },
        { value: "261", id: 2 },
        { value: "27", id: 3 }
      ],
      loading: false,
      isExist: true,
      isEdit: false,
      isShow: false,
      namesearch: "",
      list_paged: [],
      page: 1,
      pagestat: 1,
      count: 1,
      pageSize: 10,
      pageSizes: [10, 50, 100, 500]
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    handlePageChange(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      if (this.namesearch == "") {
        this.fetchData();
      } else {
        this.searchdata();
      }
    },
    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      if (this.namesearch == "") {
        this.fetchData();
      } else {
        this.searchdata();
      }
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    },
    async fetchData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        "fg_outgoing_h/getalldatafg_outgoing_h?length=" +
        this.pageSize +
        "&page=" +
        this.page;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          if (resp.data.data.length == 0) {
            this.isExist = false;
          } else {
            this.isExist = true;
            this.list_paged = resp.data.data;
            this.count = resp.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          });
        });
    },
    searchdata() {
      this.loading = true;
      const params = this.getRequestParams(this.page);
      //const params = this.getRequestParams(this.pagestat);
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        "fg_outgoing_h/getalldatafg_outgoing_h?cari=" +
        this.namesearch +
        "&length=" +
        this.pageSize;
      axios
        .get(urlAPIget, { params, headers: { Authorization: tokenlogin } })
        .then((resp) => {
          this.list_paged = resp.data.data;
          this.count = resp.data.datatotalcount;
          this.loading = false;
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          });
        });
    },
    saveData() {
      var np = this.isidata.nomorpengeluaran;
      var tp = this.isidata.tglpengeluaran;
      var kb = this.isidata.kodebuyer;
      var nk = this.isidata.nomorkepabeanan;
      var tk = this.isidata.tglkepabeanan;
      var bt = this.isidata.bctype;
      var ni = this.isidata.nomorinvoice;
      if (np == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Out Number can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (tp == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Out Date can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (kb == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Buyer Code can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (nk == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Customs Number can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (tk == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Customs Date can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (bt == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "BC Type can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (ni == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Invoice Number can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        var kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          nomor_pengeluaran: np,
          tanggal_pengeluaran: tp,
          kode_buyer: kb,
          nomor_kepabeanan: nk,
          kode_user: kodeuser,
          tanggal_kepabeanan: tk,
          jenis_kepabeanan: bt,
          no_invoice: ni
        };

        const tokenlogin = sessionStorage.getItem("token");
        const urlAPISaveData = this.$apiurl + "fg_outgoing_h/savefg_outgoing_h";
        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .post(urlAPISaveData, paramdata, { headers })
          .then((resp) => {
            console.log(resp);
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Save data successfully",
                showConfirmButton: false
              });
              this.isidata.nomorpengeluaran = "";
              this.isidata.kodebuyer = "";
              this.isidata.tglpengeluaran = "";
              this.isidata.nomorkepabeanan = "";
              this.isidata.tglkepabeanan = "";
              this.isidata.bctype = "";
              this.isidata.nomorinvoice = "";
              this.loading = false;
              this.fetchData();
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    },
    EditData(id) {
      this.loading = true;
      this.isEdit = true;

      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl + "fg_outgoing_h/getfg_outgoing_hbyid?id=" + id;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          this.isidata.idfgout = resp.data.data.ID;
          this.isidata.nomorpengeluaran = resp.data.data.nomor_pengeluaran;
          this.isidata.tglpengeluaran = resp.data.data.tanggal_pengeluaran;
          this.isidata.kodebuyer = resp.data.data.kode_buyer;
          this.isidata.nomorkepabeanan = resp.data.data.nomor_kepabeanan;
          this.isidata.tglkepabeanan = resp.data.data.tanggal_kepabeanan;
          this.isidata.bctype = resp.data.data.jenis_kepabeanan;
          console.log(this.isidata.tanggal_pengeluaran);
          this.isidata.nomorinvoice = resp.data.data.no_invoice;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateData() {
      this.loading = true;
      var np = this.isidata.nomorpengeluaran;
      var tp = this.isidata.tglpengeluaran;
      var kb = this.isidata.kodebuyer;
      var nk = this.isidata.nomorkepabeanan;
      var tk = this.isidata.tglkepabeanan;
      var bt = this.isidata.bctype;
      var ni = this.isidata.nomorinvoice;
      var idx = this.isidata.idfgout;
      if (np == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Out Number can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (tp == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Out Date can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (kb == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Buyer Code can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (nk == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Customs Number can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (tk == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Customs Date can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (bt == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "BC Type can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (ni == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Invoice Number can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          nomor_pengeluaran: np,
          tanggal_pengeluaran: tp,
          kode_buyer: kb,
          nomor_kepabeanan: nk,
          kode_user: kodeuser,
          tanggal_kepabeanan: tk,
          jenis_kepabeanan: bt,
          no_invoice: ni
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        const urlAPIUpdate =
          this.$apiurl + "fg_outgoing_h/updatefg_outgoing_h/" + idx;

        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .put(urlAPIUpdate, paramdata, { headers: headers })
          .then((resp) => {
            // console.log(resp);
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Update data successfully",
                showConfirmButton: false
              });
              this.loading = false;
              this.fetchData();
              this.isidata.nomorpengeluaran = "";
              this.isidata.tglpengeluaran = "";
              this.isidata.kodebuyer = "";
              this.isidata.nomorkepabeanan = "";
              this.isidata.tglkepabeanan = "";
              this.isidata.bctype = "";
              this.isidata.nomorinvoice = "";
              this.isidata.idfgout = "";
              this.isEdit = false;
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Update data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    },
    deleteData(id) {
      this.loading = true;
      swal({
        title: "Are you sure ?",
        text: "The selected data will be deleted and cannot be restored",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then((willdelete) => {
        if (willdelete) {
          // const tokenlogin = localStorage.getItem("token");
          const tokenlogin = sessionStorage.getItem("token");
          const urlAPIbrgdel =
            this.$apiurl + "fg_outgoing_h/hapusfg_outgoing_h/" + id;
          const headers = {
            Accept: "application/json",
            Authorization: tokenlogin
          };
          axios
            .delete(urlAPIbrgdel, { headers: headers })
            .then((resp) => {
              if (resp.data.status == true) {
                swal({
                  icon: "success",
                  title: "Success",
                  text: "Delete data successfully"
                }).then(function() {
                  window.location.reload();
                });
              } else {
                swal({
                  icon: "warning",
                  title: "Warning",
                  text: "Delete data failed"
                }).then(function() {
                  window.location.reload();
                });
              }
            })
            .catch((err) => {
              swal({
                icon: "warning",
                title: "Warning",
                text: err.response.data
              });
            });
        } else {
          this.loading = false;
        }
      });
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
